import { Vue, Component, Prop } from "@wagich/vue-facing-decorator-metadata";
import Cookies from "js-cookie";

import { render } from "./notification-modal.html";

@Component({
	render
})
export default class NotificationModal extends Vue {
	private readonly CookieName = "notification_shown";

	@Prop()
	title!: string;

	@Prop()
	text!: string;

	isActive: boolean = false;

	get notificationShown(): boolean {
		return !!Cookies.get(this.CookieName);
	}
	set notificationShown(value: boolean) {
		Cookies.set(this.CookieName, value.toString());
	}

	mounted() {
		if (!this.notificationShown) {
			this.isActive = true;
			this.notificationShown = true;
		}
	}
}
