import { Component, Vue, Prop } from "@wagich/vue-facing-decorator-metadata";
import { render } from "./simple-tooltip.html";

@Component({
	render,
})
export default class SimpleTooltip extends Vue {
	@Prop({ default: false }) interactive: boolean;
	@Prop({ default: null }) triggers: string | null;
	@Prop({ default: null }) autoClose: string | boolean | null;

	get triggersParsed(): string[] | undefined {
		if (this.triggers == null) {
			return undefined;
		}
		return this.triggers.split(" ").map(x => x.trim());
	}

	get autoCloseParsed(): string[] | boolean | undefined {
		if (this.autoClose == null) {
			return undefined;
		}
		if (typeof this.autoClose === "boolean") {
			return this.autoClose;
		}
		return this.autoClose.split(" ").map(x => x.trim());
	}
}
