import { Vue, Component, Prop, Watch } from "@wagich/vue-facing-decorator-metadata";
import { h } from "vue";

@Component({
	render: function (this: SvgIcon) {
		return h("svg", this.name != null ? { class: "icon" } : null, [
			h("use", { "xlink:href": this.svgHref })
		]);
	},
})
export class SvgIcon extends Vue {
	@Prop() icon: string[];
	@Prop() size: string;
	@Prop({required: false}) name: string;

	get svgHref(): string {
		return `#icon:${this.name ?? this.icon[1]}`;
	}
}
