import "reflect-metadata";

import { defineCustomElement as defineCustomElementCore, type App, type Component } from "vue";
import { ConfigProgrammatic, Button, Tooltip, Field, Input, Select, Notification, Modal, Toast } from "buefy";
import { BRadioButton } from "buefy/dist/esm/radio";
import PortalVue from "portal-vue";
import EasySpinner from "vue-easy-spinner";

import { formatDate, humanizeDate } from "~/utils";
import { Page } from "./page";
import { SvgIcon } from "~/common/svg-icon";
import SimpleTooltip from "~/common/simple-tooltip";
import NotificationModal from "~/common/notification-modal";

ConfigProgrammatic.setOptions({
	defaultIconComponent: SvgIcon as any,
	defaultIconPack: "icon",
});

function defineAndConfigureCustomElement(component: any) {
	return defineCustomElementCore(component.__vccOpts, {
		shadowRoot: false,
		configureApp(app) {
			app.component("svg-icon", SvgIcon);

			app.use(Select);
			app.use(Button);
			app.use(Tooltip);
			app.use(Field);
			app.use(Input);
			app.use(Notification);
			app.use(Modal);
			app.use(Toast);
			app.component("b-radio-button", BRadioButton);

			app.use(PortalVue);
			app.use(EasySpinner);

			app.config.globalProperties.$formatters = {
				formatDate,
				humanizeDate,
			};

			//app.config.globalProperties.$colors = {};
		},
	})
}

customElements.define("simple-tooltip", defineAndConfigureCustomElement(SimpleTooltip));
customElements.define("notification-modal", defineAndConfigureCustomElement(NotificationModal));

if (document.querySelector("movie-overview") != null) {
	import("./filter/movie-overview").then(m => {
		customElements.define("movie-overview", defineAndConfigureCustomElement(m.default));
	});
}

if (document.querySelector("movie-tickets") != null) {
	import("./tickets/movie-tickets").then(m => {
		customElements.define("movie-tickets", defineAndConfigureCustomElement(m.default));
	});
}

if (document.querySelector("special-ticket") != null) {
	import("./tickets/special-ticket").then(m => {
		customElements.define("special-ticket", defineAndConfigureCustomElement(m.default));
	});
}

new Page();

import SlabText from "./slab-text";
let slogan = document.querySelector(".slogan");
if (slogan != null) {
	window.addEventListener("load", () => new SlabText(slogan!));
}

import { initializeMaps } from "@wagich/bluc-map";
initializeMaps();

import "../Styles/index.scss";
