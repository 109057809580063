export interface Options {
	/**
	 * The ratio used when calculating the characters per line
	 * (parent width / (font-size * fontRatio)).
	 * Defaults to 0.78
	 */
	fontRatio: number;

	/**
	 * Always recalculate the characters per line, not just when * the font-size changes? 
	 * Defaults to true (CPU intensive)
	 */
	forceNewCharCount: boolean;

	/**
	 * Do we wrap ampersands in <span class="amp">
	 * Defaults to true.
	 */
	wrapAmpersand: boolean;

	/**
	 * Under what pixel width do we remove the slabtext styling?
	 */
	headerBreakpoint: number | null;

	/**
	 * Under what pixel width do we remove the slabtext styling?
	 */
	viewportBreakpoint: number | null;

	/**
	 * Don't attach a resize event
	 * Defaults to false.
	 */
	noResizeEvent: boolean;

	/**
	 * The time in milliseconds for throttling the resize event.
	 * Defaults to 300.
	 */
	resizeThrottleTime: number;

	/**
	 * The maximum font size in pixels the script can set.
	 * Defaults to 999.
	 */
	maxFontSize: number;

	/**
	 * Do we try to tweak the letter-spacing or word-spacing?
	 */
	postTweak: boolean;

	/**
	 * Decimal precision to use when setting CSS values.
	 * Defaults to 3
	 */
	precision: number;

	/**
	 * The minimum number of characters a line has to contain.
	 * Defaults to 0.
	 */
	minCharsPerLine: number;

	/**
	 * Callback function fired after the headline is redrawn.
	 */
	onRender?: () => void;
}

const defaultOptions: Options = {
	fontRatio: 0.78,
	forceNewCharCount: true,
	wrapAmpersand: true,
	headerBreakpoint: null,
	viewportBreakpoint: null,
	noResizeEvent: false,
	resizeThrottleTime: 300,
	maxFontSize: 999,
	postTweak: true,
	precision: 3,
	minCharsPerLine: 0,
};

const styles = ".slabtexted .slabtext{display:-moz-inline-box;display:inline-block;white-space:nowrap}.slabtextinactive .slabtext{display:inline;white-space:normal;font-size:1em !important;letter-spacing:inherit !important;word-spacing:inherit !important;*letter-spacing:normal !important;*word-spacing:normal !important}.slabtextdone .slabtext{display:block}";

export default class SlabText {
	private _element: Element;
	private _settings: Options;
	private _keepSpans: boolean;
	private _words: string[];
	private _headLinkTitle: string | null | undefined;
	private _headLink: string | null;
	private _htmlEscapeHelper: HTMLDivElement;

	constructor(element: Element, options?: Partial<Options>) {
		this._htmlEscapeHelper = document.createElement("div");

		this._element = element;
		this._settings = Object.assign({}, defaultOptions, options);

		if (!document.body.classList.contains("slabtexted")) {
			let style = document.createElement("style");
			style.type = "text/css";
			style.innerHTML = styles;
			document.head.appendChild(style);

			// Add the slabtexted classname to the body to initiate the styling of the injected spans
			document.body.classList.add("slabtexted");
		}

		this._keepSpans = element.querySelectorAll("span.slabtext").length > 0;
		this._words = this._keepSpans
			? []
			: String(element.textContent?.trim().replace(/\s{2,}/g, " ")).split(" ");

		this._headLink = element.querySelector("a")?.getAttribute("href") || element.getAttribute("href");
		this._headLinkTitle = this._headLink ? document.querySelector("a")?.getAttribute("title") : "";

		if (!this._keepSpans && this._settings.minCharsPerLine && this._words.join(" ").length < this._settings.minCharsPerLine) {
			return;
		};

		// Immediate resize
		this.resizeSlabs();

		if (!this._settings.noResizeEvent) {
			let resizeThrottle: number | null = null;
			let viewportWidth = window.innerWidth;

			document.addEventListener("resize", () => {
				// Only run the resize code if the viewport width has changed.
				// we ignore the viewport height as it will be constantly changing.
				if (window.innerWidth == viewportWidth) {
					return;
				};

				if (resizeThrottle != null) {
					clearTimeout(resizeThrottle);
				}
				resizeThrottle = window.setTimeout(() => this.resizeSlabs(), this._settings.resizeThrottleTime);
			});
		};
	}

	// Most of this function is a (very) stripped down AS3 to JS port of
	// the slabtype algorithm by Eric Loyer with the original comments
	// left intact
	// http://erikloyer.com/index.php/blog/the_slabtype_algorithm_part_1_background/
	private resizeSlabs() {
		// Cache the parent containers width
		let parentWidth = this._element.getBoundingClientRect().width;

		// Sanity check to prevent infinite loop
		if (parentWidth == 0) {
			return;
		};

		// Remove the slabtextdone and slabtextinactive classnames to enable the inline-block shrink-wrap effect
		this._element.classList.remove("slabtextdone", "slabtextinactive");

		if (this._settings.viewportBreakpoint && this._settings.viewportBreakpoint > window.innerWidth
			|| this._settings.headerBreakpoint && this._settings.headerBreakpoint > parentWidth) {
			// Add the slabtextinactive classname to set the spans as inline
			// and to reset the font-size to 1em (inherit won't work in IE6/7)
			this._element.classList.add("slabtextinactive");
			return;
		};

		let fontSize = this.grabPixelFontSize();
		// If the parent containers font-size has changed or the "forceNewCharCount" option is true (the default),
		// then recalculate the "characters per line" count and re-render the inner spans
		// Setting "forceNewCharCount" to false will save CPU cycles...
		let origFontSize = 0;
		let idealCharPerLine = 0;
		if (!this._keepSpans && (this._settings.forceNewCharCount || fontSize != origFontSize)) {

			origFontSize = fontSize;

			let newCharPerLine = Math.min(60, Math.floor(parentWidth / (origFontSize * this._settings.fontRatio)));
			let wordIndex = 0;
			let lineText = [];

			if (newCharPerLine != 0 && newCharPerLine != idealCharPerLine) {
				idealCharPerLine = newCharPerLine;

				while (wordIndex < this._words.length) {
					let preText = "";
					let postText = "";
					let finalText = "";

					// build two strings (preText and postText) word by word, with one
					// string always one word behind the other, until
					// the length of one string is less than the ideal number of characters
					// per line, while the length of the other is greater than that ideal
					while (postText.length < idealCharPerLine) {
						preText = postText;
						postText += this._words[wordIndex] + " ";
						if (++wordIndex >= this._words.length) {
							break;
						};
					};

					// This bit hacks in a minimum characters per line test
					// on the last line
					if (this._settings.minCharsPerLine) {
						let slice = this._words.slice(wordIndex).join(" ");
						if (slice.length < this._settings.minCharsPerLine) {
							postText += slice;
							preText = postText;
							wordIndex = this._words.length + 2;
						};
					};

					// calculate the character difference between the two strings and the
					// ideal number of characters per line
					let preDiff = idealCharPerLine - preText.length;
					let postDiff = postText.length - idealCharPerLine;

					// if the smaller string is closer to the length of the ideal than
					// the longer string, and doesn’t contain less than minCharsPerLine
					// characters, then use that one for the line
					if ((preDiff < postDiff) && (preText.length >= (this._settings.minCharsPerLine || 2))) {
						finalText = preText;
						wordIndex--;
					} else {
						// otherwise, use the longer string for the line
						finalText = postText;
					};

					let lineLength = finalText.trim().length;

					// HTML-escape the text
					this._htmlEscapeHelper.textContent = finalText
					finalText = this._htmlEscapeHelper.innerHTML;

					// Wrap ampersands in spans with class `amp` for specific styling
					if (this._settings.wrapAmpersand) {
						finalText = finalText.replace(/&amp;/g, `<span class="amp">&amp;</span>`);
					};

					lineText.push(`<span class="slabtext slabtext-linesize-${Math.floor(lineLength / 10)} slabtext-linelength-${lineLength}">${finalText.trim()}</span>`);
				};

				this._element.innerHTML = lineText.join(" ");

				// If we have a headLink, add it back just inside our target, around all the slabText spans
				if (this._headLink) {
					let children = this._element.children;

					let a = document.createElement("a");
					a.href = this._headLink;
					if (this._headLinkTitle != null) {
						a.title = this._headLinkTitle;
					};
					a.append(...children);

					this._element.replaceChildren(a);
				};
			};
		} else {
			// We only need the font-size for the resize-to-fit functionality
			// if not injecting the spans
			origFontSize = fontSize;
		};

		for (let span of this._element.querySelectorAll<HTMLSpanElement>("span.slabtext")) {
			let innerText = span.textContent ?? "";
			let wordSpacing = innerText.split(" ").length > 1;

			if (this._settings.postTweak) {
				span.style.wordSpacing = "0";
				span.style.letterSpacing = "0";
			};

			let ratio = parentWidth / span.clientWidth;
			let fontSize = parseFloat(span.style.fontSize) || origFontSize;

			span.style.fontSize = `${Math.min(parseFloat((fontSize * ratio).toFixed(this._settings.precision)), this._settings.maxFontSize)}px`;

			// Do we still have space to try to fill or crop
			let diff = this._settings.postTweak
				? parentWidth - span.clientWidth
				: false;

			// A "dumb" tweak in the blind hope that the browser will
			// resize the text to better fit the available space.
			// Better "dumb" and fast...
			if (diff) {
				let cssValue = `${(diff / (wordSpacing ? innerText.split(" ").length - 1 : innerText.length)).toFixed(this._settings.precision)}px`;
				if (wordSpacing) {
					span.style.wordSpacing = cssValue;
				} else {
					span.style.letterSpacing = cssValue;
				}
			};
		}

		// Add the class slabtextdone to set a display:block on the child spans
		// and avoid styling & layout issues associated with inline-block
		this._element.classList.add("slabtextdone");

		// Fire the callback if required
		if (typeof this._settings.onRender === "function") {
			this._settings.onRender.call(this);
		}
	}

	// Calculates the pixel equivalent of 1em within the current header
	private grabPixelFontSize(): number {
		let dummy = document.createElement("div");
		dummy.style.fontSize = "1em";
		dummy.style.margin = "0";
		dummy.style.padding = "0";
		dummy.style.height = "auto";
		dummy.style.lineHeight = "1";
		dummy.style.border = "0";
		dummy.innerHTML = "&nbsp;";

		this._element.append(dummy);
		let emHeight = dummy.clientHeight;
		dummy.remove();

		return emHeight;
	}
}
